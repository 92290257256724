/* -------------------------------------------------------------- */
$body-bg: #f3f3f3;
$sidebar-bg: #181e22;

$navbar-bg: #002045;
$navbar-border: (
  bottom: (
    size: 0px,
    style: solid,
    color: #fff
  )
);

$default-color: #d9d9d9;
$color1: #6b7a8f;
$color2: #f7882f;
$color3: #f7c331;
$color4: #dcc7aa;

// Font
$font-default: #FFFFFF;
$font-primary-color: $color1;
$font-secondary-color: $color4; 
$font-link-hv: $color2;


// Layout
$bg-primary-color: $default-color;
$bg-secondary-color: $color4;


// colors
$bar-primary-color: #002045;
$bar-secondary-color: #FD823A;

// Buttons
$btn-primary-color: #002045;
$btn-secondary-color: #FD823A;
$btn-hover-color: #FD823A;

/* -------------------------------------------------------------- */


*{
  margin: 0;
  padding: 0;
  color: $font-default;
}

html{
  scroll-behavior: smooth;
}
/* -------------------------------------------------------------- */



.fall {
	background-image: linear-gradient(rgba(0,0,0,0.9), rgba(0,0,0,0.02));
	min-height: 100vh;
	position: relative;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-content: center;
}

	__content {
		position: relative;
		align-self: center;
		padding: 3rem 0;
	}

  .anim {
    position: absolute;
    min-width: 100vw;
    min-height: 100vh;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }

  .anim .svg {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .anim #iWithman{
    color: $font-default;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 600;
    position: absolute;
    z-index: 999;
    margin: 0 auto;
    transform: translateY(-10%);
    left: 0;
    right: 0;
    top: 9%; 
    text-align: center;
    width: 60%; 
  }

  .anim #iWithman img{
    max-width: 40vh ;
    stroke-linejoin: round;
    stroke-width: 2.5px;
  }


  #anim-greeting{
    display: inline-block;
  }

  #anim-greeting span{
    position: absolute;
    top: 0;
    overflow: hidden;
    animation: animate 5s linear infinite;
    opacity: 0;
  }

  @keyframes animate{
    0%{
      opacity: 0;
      transform: translateY(50px)
    }
    2%{
      opacity: 1;
      transform: translateY(0px)
    }
    18%{
      opacity: 1;
      transform: translateY(0px)
    }
    20%{
      opacity: 0;
      transform: translateY(50px)
    }
    100%{
      opacity: 0;
      transform: translateY(50px)
    }
  }
  
  #greeting p{
    font-size: 25px;
  }

  .anim #iWithman span{
    color: $color4;
  }
  

  #anim-top-layer {
    will-change: transform;
    transform: translateY(-768px);
    animation: fall 22.5s infinite linear;
  }

  #anim-bottom-layer {
    will-change: transform;
    transform: translateY(-768px);
    animation: fall 45s infinite linear;
  }

  @keyframes fall {
    100% {
      transform: translateY(0);
    }
  }





.anim #iWithman #connect ul li{
  font-size: 40px;
  padding: 20px;
  text-shadow: 0 1px 1px $color2;
}


.anim #iWithman #connect ul li i{
  content: icon;
  color: $color4;
}

.anim #iWithman #connect ul li i:hover{
  font-size: 80px;
}


.anim #iWithman #connect ul li{
  display: inline;
  list-style-type: none;
  
}


.scroll-down{
  margin-top: 20px;
  padding-top: 5px; 
  position: absolute;
  top: 90%;
  left: 48%;
  font-size: 50px;
  animation: blinkingText 0.7s infinite;
  
}

@keyframes blinkingText{
  0%{     color: $color1;    }
  45%{    color: transparent; }
  70%{     color: $color2;    }
  99%{    color:transparent;  }
  100%{   color: $color3;    }
}


/* -------------------------------------------------------------- */

#nav-bar{
  background-image: linear-gradient(rgba(0,0,0,0.01), rgba(0,0,0,0));
  position: sticky;
  top: 0;
  z-index: 10;
  /* border-bottom: 2px solid #99d3df; */
}

#navbarNav{
  top: 0;
}

.navbar-brand img{
  height: 30px;
  padding-left: 30px;
}

.navbar-nav li{

  padding: 0 10px;
}

.navbar-nav li a{
  float: right;
  text-align: left;
}

#nav-bar ul li a:hover{

  color: grey!important
}

.navbar{

  background-image: linear-gradient(rgba(0,0,0,.01), rgba(0,0,0,0.2));
}

.navbar-toggler{
  border:none!important;
}

.nav-link{
  color: $color1!important;
  font-weight: 600;
  font-size: 16px;
}


.my-resume{
  /* border: 2px solid #fff; */
  border-radius: 40%;
  animation: blinkingText 3s infinite;
}

@keyframes blinkingText{
  0%{     color: #173e43;    }
  45%{     color: $color1;    }
  75%{     color: $color2;    }
  90%{     color: $color3;    }
  100%{   color: $color4;    }
}

.my-resume:hover{
  animation: blinkingText 0s;
}


/* -------------------------------------------------------------- */



h1{
  text-align: center;
  color:$font-default;
  padding-bottom: 10px;
}

h1::after {
  content: '';
  background: $color2!important;
  display: block!important;
  height: 1px!important;
  width: 140px!important;
  margin: 5px auto 5px!important;
}




/* -------------------------------------------------------------- */


#about{
  padding: 12px;
  // background-color: $bg-primary-color;
  /* background-image: linear-gradient(rgba(0,0,0,0.8), rgba(0,0,0,0.8)),url("images/program.png"); */
  background-size: cover;
  background-position: center;
  color: $font-secondary-color !important;
  background-attachment: fixed;
  padding-top: 50px;
  padding-bottom: 50px;
}

#about .card {
  border: none;
}

#about #container-card {
  padding: 10px;
}

#about h1{
  color: $font-primary-color;
}

#me-container p{
  color: $font-primary-color;
  padding: 20px;
}

#me-container i{
  background-color: $color2;
  border-radius:50%;
  display:inline-block;
  padding:3px;
	height: auto;
	float: left;
	margin: 0 2rem 1rem 0; 
	shape-outside:circle();
}

#me-container span{
  color: $color2;
}

.me{
  border-radius: 50%;
  width: 150px;
}

#about #skills-card {
  background-color: $color1;
}

#about .skills-bar p{
  color: $font-default;
  margin-bottom: 6px;
  font-weight: 600;
}

#about .text-center {
  color: $font-default !important;
}

.progress{
  /* border-radius: 16px !important; */
  border-radius: 0 16px 16px 0;
  margin-bottom: 20px;
}

.progress-bar {
  background-color: $color2 !important;
}

.container-1{
  display: flex;
  justify-content: space-around;
  padding-bottom: 15px;
}

.container-1 li{
  list-style-type: none;
  padding: 6px 0 33px 0;
}

#extra-card{
  background-color: $color1;
}

#extra-card h4{
  color: #e3e3e3;
}

#extra-card li{
  color: #e3e3e3;
  font-weight: 600;
  padding: 20px ;
  list-style-type: none;
}


/* -------------------------------------------------------------- */


#projects{
  padding-top: 50px;
  padding-bottom: 50px;
}

div #projects{
  
}


#projects-flex{

}




.box-1{
  width: 20%;
}

.box-2{
  flex: 1;
  order: 1;
}

.box-3{
  flex: 1;
  order: 3;
}

/* -------------------------------------------------------------- */


div #contact{
  /* -webkit-clip-path: polygon(50% 17%, 100% 0, 100% 84%, 50% 100%, 0 84%, 0 0); */
clip-path: polygon(50% 17%, 100% 0, 100% 84%, 100% 100%, 0 100%, 0 0);
   -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;  
}

#contact{
  background-image: linear-gradient(rgba(0,0,0,0.1), rgba(0,0,0,0.7)), url('./images/iWithman-1.png');
  background-size: cover;
  background-position: center;
  color: #efefef !important;
  background-attachment: fixed;
  position: relative;
  width: 100%;
  
}

#contact .cont{
  position: relative;
  animation: slide-left 5s;
}
@keyframes slide-left {
  from {
    margin-left: 100%;
    width: 50%; 
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}

#contact h1{
  position: relative;
  top: 10vh;
  
  color: $font-default;
}

.fa-envelope {
  color: $color4 !important;
}

#contact .cont #email{
  font-size: 10vw;
  margin: 70px 45vw auto;  
  text-shadow: 0 1px 2px #fff;
}





/* -------------------------------------------------------------- */

.footer-container{
  height: 200px;
  background-image: linear-gradient(rgba(0,0,0,0),rgba(0,0,0,.5));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  
}

.social-list{
  display: flex;

}

.social-list .icon {
  border-radius: 5px;
  border: 1px solid $color1;
  color: #fff;
  background-color: $color2;
  padding: 12px 14px 12px 14px;
  margin: 10px;
  font-size: 25px;
}

.social-list .icon:hover{
  box-shadow: 0 2px 20px #000000;
  color: $font-default;
  background-color: $color1;
  padding: 15px;
}

#footer-me{
  font-weight: 600;
  color: #fff;
}

#footer-me span{
  color: $color1;
}